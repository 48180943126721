import React, {Fragment, useEffect, useState} from "react";
import { BrowserView, MobileView } from 'react-device-detect';
import Slider from "react-slick";
import News from "../components/news";
import Programs from "../components/programs";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Home = () => {

    var settings = {
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false
      };

      const [loader, setLoader] = useState(false);

      const [slider, setSlider] = useState([{
        acf: { 
            slider_desktop: "",
            slider_movil: ""
        }
    }]);
    
    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
    
       
        fetch("https://radiovoxi.com/cms/wp-json/wp/v2/slider", requestOptions)
        .then(response => response.json())
        .then(result => {
            setSlider(result);
        })
        .then(
            setTimeout(() => {
                setLoader(true);
          }, "1000"))
        .catch(error => console.log('error', error));
    
        },[]);

    return(
        <div id="container">
        { loader === true ?
            <div className="slider">
            <BrowserView>
            <Slider {...settings}>
                 {
                slider.map(( desktop, index) => (
                    <Fragment key={index}>
                    <div>
                    { desktop.acf.url ?
                    <a href={desktop.acf.url} target="_blank" rel="noreferrer"><img src={desktop.acf.slider_desktop} alt="banner"></img></a>
                   :
                   <img src={desktop.acf.slider_desktop} alt="banner"></img>
                    }
                    </div>
                    </Fragment>
                ))
            }
            
             
            </Slider>
            </BrowserView>
            <MobileView>
            <Slider {...settings}>
            {
                slider.map(( movil, index) => (
                    <Fragment key={index}>
                     <div>
                     { movil.acf.url ?
                     <a href={movil.acf.url} target="_blank" rel="noreferrer"><img src={movil.acf.slider_movil} alt="banner"></img></a>
                    :
                    <img src={movil.acf.slider_movil} alt="banner"></img>
                     }
                     </div>
                     </Fragment>
                ))
            }
            </Slider>
            </MobileView>
            <Programs />
            <News />
            </div>
            :
            <div id="loader">
                <img src="./img/loader.gif" alt="loader"></img>
            </div>
            }
        </div>
    );
};

export default Home;
import React, {useEffect, Fragment, useState} from "react";
import parse from "html-react-parser";

const Programs = () => {

const [program, setProgram] = useState([{
    "content": {
        "rendered": "",   
        },
    "fimg_url": ""
}]);

useEffect(()=> {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };

   
    fetch("https://radiovoxi.com/cms/wp-json/wp/v2/programas", requestOptions)
    .then(response => response.json())
    .then(result => {
        setProgram(result);
    })
    .catch(error => console.log('error', error));
},[])

    return(
        <div className="content programs">
          <h1>PROGRAMAS</h1>
            <div id="programs">
                {
                    program.map((program, index) => (
                        <Fragment key={index}>
                              <div className="item_programs">
                                <img src={program.fimg_url} alt="programas"></img>
                                {
                                    program.content.rendered  ? 
                                    <div className="item_programs_text">{parse(program.content.rendered)}</div>
                                    :
                                    null
                                }
                               
                                </div>
                        </Fragment>
                  ))
                }
                  <div className="clear"></div>
            </div>
        </div>
    );
};

export default Programs;
import React from "react";

const Footer = () => {
    return(
        <div id="footer">
              {/* <div id="logo_footer">
                <img src="../img/footer-logo-voxi.png" alt="logo"></img>
              </div> 
              <div id="separate_footer"></div> */}
              <div id="copy">
                 Radio Voxi © 2022, todos los derechos reservados.
              </div>
              <div id="social">
            <div className="social_red"><img src="../img/iconos-facebook-voxi.png" alt="facebook"></img></div>
            <div className="social_red"><img src="../img/iconos-twitter-voxi.png" alt="twitter"></img></div>
            <div className="social_red"><a href="https://instagram.com/voxiradio?igshid=YmMyMTA2M2Y=" rel="noreferrer" target="_blank"><img src="../img/iconos-instagram-voxi.png" alt="instagram"></img></a></div>
            <div className="social_red"><img src="../img/iconos-youtube-voxi.png" alt="youtube"></img></div>
            <div className="clear"></div>
        </div>
        </div>
    );
};

export default Footer;
import React, { useEffect, useState } from "react";

const News = () => {

    const [data, setData] = useState([{
        fimg_url:"",
        acf: { link_instagram: "" }
    }]);
    
    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
    
       
        fetch("https://radiovoxi.com/cms/wp-json/wp/v2/posts", requestOptions)
        .then(response => response.json())
        .then(result => {
            setData(result);
        })
        .catch(error => console.log('error', error));
    
        },[]);


  return(
    <div id="novedades">
          <div className="clear"></div>
    <div className="content">
        <h1>novedades</h1>
      
        {
            data.map((news, index) => (
                <div className="new" key={index}><a href={news.acf.link_instagram} rel="noreferrer" target="_blank"><img src={news.fimg_url} alt="news"></img></a></div>
            ))
        }
        <div className="clear"></div>
    </div>
    </div>
    );
};

export default News;